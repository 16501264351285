










import { Component, Vue } from "vue-property-decorator";
import store from "@/store";
// types
import { ExtendedVessel } from "@/types/Vessel";
import { Condition } from "@/types/condition";
import { NotificationConditions } from "@/types/NotificationConditions";

// components
import ConditionStepper from "@/components/Notifications/ConditionStepper.vue";

// modules
import { getModule } from "vuex-module-decorators";
import IncidentsModule from "@/store/clients/Incidents.module";
import SnackbarModule from "@/store/clients/Snackbar.module";

const Incidents = getModule(IncidentsModule, store);
const Snackbar = getModule(SnackbarModule, store);

@Component({
  components: {
    ConditionStepper,
  },
})
export default class AddNotification extends Vue {
  notification: NotificationConditions = {
    vessels: [] as ExtendedVessel[],
    conditions: [] as Condition[],
  } as NotificationConditions;

  //  @Methods
  async onNotificationCreate(notification: NotificationConditions): Promise<void> {
    try {
      await Incidents.createConditions(notification.conditions);
      Snackbar.showSnackbar({ text: "Conditions successfully created", color: "success" });
    } catch (error) {
      Snackbar.showSnackbar({ text: "Failed to create conditions" });
    }
  }
}
